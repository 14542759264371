let courses  = [
    {
        name:"Advanced Deployment Scenarios with TensorFlow",
        img_url: "../Data/images/Advanced Deployment Scenarios with TensorFlow-1.jpg"
    },
    {
        name:"Browser-based Models with TensorFlow.js",
        img_url: "./images/Browser-based Models with TensorFlow.js-1.jpg"
    },
    {
        name:"Build Basic Generative Adversarial Networks",
        img_url: "./images/Build Basic Generative Adversarial Networks-1.jpg"
    },
    {
        name:"Convolutional Neural Networks in TensorFlow",
        img_url: "./images/Convolutional Neural Networks in TensorFlow-1.jpg"
    },
    {
        name:"Convolutional Neural Networks",
        img_url: "./images/Convolutional Neural Networks-1.jpg"
    },
    {
        name:"Data Pipelines with TensorFlow Data Services",
        img_url: "./images/Data Pipelines with TensorFlow Data Services-1.jpg"
    },
   
    {
        name:"Device-based Models with TensorFlow Lite",
        img_url: "./images/Device-based Models with TensorFlow Lite-1.jpg"
    },
    {
        name:"Improving Deep Neural Networks",
        img_url: "./images/Improving Deep Neural Networks-1.jpg"
    },
    {
        name:"Introduction to Data Science in Python",
        img_url: "./images/Introduction to Data Science in Python-1.jpg"
    },
    {
        name:"Introduction to TensorFlow for Artificial Intelligence",
        img_url: "./images/Introduction to TensorFlow for Artificial Intelligence-1.jpg"
    },
    {
        name:"Natural Language Processing in TensorFlow",
        img_url: "./images/Natural Language Processing in TensorFlow-1.jpg"
    },
    {
        name:"Neural Networks and Deep Learning",
        img_url: "./images/Neural Networks and Deep Learning-1.jpg"
    },
    {
        name:"Sequence Models",
        img_url: "./images/Sequence Models-1.jpg"
    },
    {
        name:"Sequences, Time Series and Prediction",
        img_url: "./images/Sequences, Time Series and Prediction-1.jpg"
    },
    {
        name:"Structuring Machine Learning Projects",
        img_url: "./images/Structuring Machine Learning Projects-1.jpg"
    },
   


]

export default courses