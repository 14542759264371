import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet';
import './About.css'

function About() {
  return (
    <motion.div className="aboutView" exit={{ opacity: 0 }} initial={{ x: "3em" }} animate={{ x: 0 }}>
      <Helmet>
        <title>About | Suyash Sonawane</title>
        <meta name="description" content="Hi I'm Suyash Sonawane 👋 Here you can know more about me, who I am ,from where and stuff ..." />
        <meta property="og:title" content="About | Suyash Sonawane" />
        <meta property="og:description" content="Hi I'm Suyash Sonawane 👋 Here you can know more about me, who I am ,from where and stuff ..." />
        <meta property="og:image" content="https://avatars0.githubusercontent.com/u/35629339?s=400&u=37fad97ae7ca47adc5405acdb1b67de97d6ff8bf&v=4" />
        <meta property="og:url" content={window.location.pathname + window.location.search} />
        <meta name="twitter:card" content="summa</meta>ry_large_image" />
      </Helmet>
      <h1>About Me</h1>
      <div className="aboutContent">

        <p>
          Hi!<br />
          I'm Suyash Yogeshwar Sonawane, a Computer Engineering Undergraduate studying at <a href="http://www.pccoepune.com/" target="_blank" rel="noreferrer" className="anchors">Pimpri Chinchwad College of Engineering, Pune </a>.
          I am a developer who is passionate about Deep Learning and Data Science.
          A few techs that I enjoy working in is Python, JS, PyTorch, TensorFlow.
        </p>
        <p>
          <strong>Current Updates</strong>
        </p>
        <ul>
          <li>&bull; 💻 SDE Intern at <a className="anchors" href="https://www.browserstack.com/" target="_blank" rel="noreferrer" >BrowserStack</a> </li>
          <li>&bull; 💻 Ex Full Stack Intern at <a className="anchors" href="https://www.coriolis.co.in/" target="_blank" rel="noreferrer" >Coriolis Technologies</a> </li>
          <li>&bull; 🦸‍♂️ Lead 2020 at <a className="anchors" href="https://dsc.community.dev/pimpri-chinchwad-college-of-engineering/" target="_blank" rel="noreferrer" > Google Developer Student Club Pimpri Chinchwad College of Engineering</a>  </li>
          <li>&bull; 🦸‍♂️ Microsoft Student Ambassador </li>
          <li>&bull; 👨‍💻 Co-Founder of <a className="anchors" href="https://devscollab.github.io/" target="_blank" rel="noreferrer" >DevsCollab</a> </li>
          <li>&bull;🔭 Worked on GANS for Fake Video Detection as AI Intern at <a className="anchors" href="https://www.cdac.in/" target="_blank" rel="noreferrer" >CDAC</a> </li>
          <li>&bull;⚡ Fun fact: Foodie, 日本語を学ぶ </li>
        </ul>

        <p>
          <strong>How I started my Journey </strong>
        </p>
        <p>
          Before going into programming, I was interested in Mechanics and Physics. I never considered Computer Engineering as a choice of higher education until the mid of 11th Standard, where I came across Python 🐍, a simple and powerful programming language.
          I was fascinated by the fact that programming computers were so much fun and intuitive. I started learning more and more about programming concepts and frameworks, and also developed some newbie projects.
        </p>

        <p>
          <strong>What excites me to Code and Learn more  </strong>
        </p>
        <p>
          All high-level concepts and ideas can be boiled down to simple step by step instructions which can be programmed and easily implemented with a computer program.
          I enjoy creating programs that can visualize and incorporate these ideas that are theoretically possible with pen and paper.
          Solving the problems which we face in our day to day life is what excites me the most.
        </p>
        <p>
          <strong>What are my paramount interests  </strong>
        </p>
        <p>
          I started learning about Machine Learning during my 12th Standard and loved the concept of developing a program that can mimic the Human Brain -a Neural Network.
          Completed the specialization offered by Coursera on Deep Learning along with different framework specializations and also developed various projects during the same.
          I have developed various Web, Android, Software, Blockchain projects owing to my curiosity about trying out new languages and frameworks.
        </p>

      </div>



    </motion.div>
  );
}

export default About;
