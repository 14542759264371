import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import * as Ai from 'react-icons/ai';
import { GiTrophy } from 'react-icons/gi'
import top_projects from '../../Data/top-projects.js'
import projects from '../../Data/projects.js'
import './Projects.css'
import { FcGoogle } from "react-icons/fc"
import { Helmet } from 'react-helmet';

function Projects() {
    return (
        <motion.div className="ProjectsView" exit={{ opacity: 0 }} initial={{ x: "-3em" }} animate={{ x: 0 }}>
            <Helmet>
                <title>Projects | Suyash Sonawane</title>
                <meta name="description" content="Hi I'm Suyash Sonawane 👋 Here you can view my Projects and know more about them along with the source code." />
                <meta property="og:title" content="Projects | Suyash Sonawane" />
                <meta property="og:description" content="Hi I'm Suyash Sonawane 👋 Here you can view my Projects and know more about them along with the source code." />
                <meta property="og:image" content="https://avatars0.githubusercontent.com/u/35629339?s=400&u=37fad97ae7ca47adc5405acdb1b67de97d6ff8bf&v=4" />
                <meta property="og:url" content={window.location.pathname + window.location.search} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <h1>Projects</h1>
            <div className="ProjectsContent">
                <div className="top-projects">
                    <h2>Top Projects</h2>
                    <div className="cards">
                        {
                            top_projects.map(e => {
                                return (
                                    <div className="card" key={e.id}>
                                        <div className="card-info" >
                                            <h3 style={{ display: 'flex' }}>
                                                {e?.star == 'Google' &&
                                                    <>
                                                        <FcGoogle fontSize={30} />
                                                        &nbsp;
                                                        &nbsp;
                                                    </>}
                                                {e?.star == 'Winner' &&
                                                    <>
                                                        <GiTrophy fontSize={30} />
                                                        &nbsp;
                                                        &nbsp;
                                                    </>}

                                                {e.title}
                                            </h3>
                                            <p>
                                                {e.short_desc}
                                            </p>
                                            <div className="tags">
                                                {e.techs.map(tech => {
                                                    return (<p>{tech}</p>)
                                                })}
                                            </div>
                                        </div>
                                        <div className="card-links">
                                            <Link className="anchors" to={`/projects/${e.id}`}>
                                                <p>Know More <Ai.AiOutlineArrowRight /></p>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>
                <div className="top-projects">
                    <h2>Learning Projects</h2>
                    <div className="cards">
                        {
                            projects.map(e => {
                                return (
                                    <div className="card" key={e.id}>
                                        <div className="card-info">
                                            <h3>
                                                {e.title}
                                            </h3>
                                            <p>
                                                {e.short_desc}
                                            </p>
                                        </div>
                                        <div className="card-links">
                                            <a className="anchors" href={e.url} target="_blank" rel="noreferrer">
                                                <p>Know More <Ai.AiOutlineArrowRight /></p>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>



            </div>


        </motion.div>
    );
}

export default Projects;
