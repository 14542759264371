import React from 'react'
import './Switch.css'
import { CgDarkMode } from 'react-icons/cg';
import { useEffect } from 'react';
export default function Switch() {

    let isDark = 'false';
    useEffect(() => {
        isDark = localStorage.getItem('isDark');
        if (isDark === 'true') {
            document.documentElement.style.setProperty("--text-main", "#ffff");
            document.documentElement.style.setProperty("--text-secondary", "#d1d1d1");
            document.documentElement.style.setProperty("--background", "#0f0f0f");
        }
        else {

            document.documentElement.style.setProperty("--text-main", "#5e5e5e");
            document.documentElement.style.setProperty("--text-secondary", "#6f6f70");
            document.documentElement.style.setProperty("--background", " #fff");
        }

    })
    const toggle = () => {
        if (isDark) {
            localStorage.setItem('isDark', 'false');
            document.documentElement.style.setProperty("--text-main", "#5e5e5e");
            document.documentElement.style.setProperty("--text-secondary", "#6f6f70");
            document.documentElement.style.setProperty("--background", " #fff");
            isDark = false;
        }
        else {
            localStorage.setItem('isDark', 'true');
            document.documentElement.style.setProperty("--text-main", "#ffff");
            document.documentElement.style.setProperty("--text-secondary", "#d1d1d1");
            document.documentElement.style.setProperty("--background", "#0f0f0f");
            isDark = true;
        }


    }
    return (
        <div className="switch">
            <CgDarkMode className="switch-icons" onClick={toggle}></CgDarkMode>
        </div>
    )
}
