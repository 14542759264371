import { motion } from 'framer-motion'
import * as Ai from 'react-icons/ai';
import './Accomplishments.css'
import accomplishments from '../../Data/accomplishments'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Accomplishments() {
  return (
    <motion.div className="AccomplishmentsView" exit={{ opacity: 0 }} initial={{ y: "3em" }} animate={{ y: 0 }}>
      <Helmet>
        <title>Accomplishments | Suyash Sonawane</title>
        <meta name="description" content="Hi I'm Suyash Sonawane 👋 Here can you view my accomplishments in various field." />
        <meta property="og:title" content="Accomplishments | Suyash Sonawane" />
        <meta property="og:description" content="Hi I'm Suyash Sonawane 👋 Here can you view my accomplishments in various field." />
        <meta property="og:image" content="https://avatars0.githubusercontent.com/u/35629339?s=400&u=37fad97ae7ca47adc5405acdb1b67de97d6ff8bf&v=4" />
        <meta property="og:url" content={window.location.pathname + window.location.search} />
        <meta name="twitter:card" content="summa</meta>ry_large_image" />
      </Helmet>
      <h1>Accomplishments</h1>
      <div className="AccomplishmentsContent">
        <div className="top-Accomplishments">
          <div className="cards">
            {
              accomplishments.map(e => {
                return (
                  <div className="card" key={e.title}>
                    {e.img && <img src={e.img} alt={e.title} />}
                    {e.embed &&
                      <td className="embed" dangerouslySetInnerHTML={{ __html: e.embed }} />

                    }
                    <div className="card-info">
                      <h3>
                        {e.title}
                      </h3>
                      <p>
                        <td dangerouslySetInnerHTML={{ __html: e.desc }} />
                        {/* {e.desc} */}
                        <br />
                        <br />
                        <b>
                          {e.date}

                        </b>
                      </p>
                      <div className="card-links">
                        {e.outlink &&
                          <a className="anchors" href={e.outlink} target="_blank" rel="noreferrer">
                            <p>{e.outInfo} <Ai.AiOutlineArrowRight /></p>
                          </a>
                        }
                        {e.inlink &&
                          <Link className="anchors" to={e.inlink} >
                            <p>Know More <Ai.AiOutlineArrowRight /></p>
                          </Link>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }


          </div>
        </div>

      </div>


    </motion.div>
  );
}

export default Accomplishments;
