let specialization = [
    {
        name: "Deep Learning",
        img_url: "Deep Learning-1.jpg"
    },
    {
        name: "Generative Adversarial Networks",
        img_url: "Generative Adversarial Networks-1.jpeg"
    },
    {
        name: "DeepLearning.AI TensorFlow Developer",
        img_url: "DeepLearning.AI TensorFlow Developer-1.jpg"
    },
    {
        name: "TensorFlow Data and Deployment",
        img_url: "TensorFlow Data and Deployment-1.jpg"
    },


]

export default specialization
