import { motion } from 'framer-motion'
import './Certificates.css'
import specialization from '../../Data/specialization.js'
import courses from '../../Data/courses.js'
import { Helmet } from 'react-helmet'

function Certificates() {
    return (
        <motion.div className="CertificatesView" exit={{ opacity: 0 }} initial={{ y: "-3em" }} animate={{ y: 0 }}>
            <Helmet>
                <title>Certifications | Suyash Sonawane</title>
                <meta name="description" content="Hi I'm Suyash Sonawane 👋 Here can you view my Specializations and Certifications in various field." />
                <meta property="og:title" content="Certifications | Suyash Sonawane" />
                <meta property="og:description" content="Hi I'm Suyash Sonawane 👋 Here can you view my Specializations and Certifications in various field." />
                <meta property="og:image" content="https://avatars0.githubusercontent.com/u/35629339?s=400&u=37fad97ae7ca47adc5405acdb1b67de97d6ff8bf&v=4" />
                <meta property="og:url" content={window.location.pathname + window.location.search} />
                <meta name="twitter:card" content="summa</meta>ry_large_image" />
            </Helmet>
            <h1>Certifications</h1>
            <div className="CertificatesContent">
                <div className="top-Certificates">
                    <h3>Specializations</h3>
                    <div className="cards">
                        {
                            specialization.map(e => {
                                return (
                                    <div className="card" key={e.name}>
                                        <img src={`https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/images/${e.name}-1.jpg`} width="300" alt={`Suyash Sonawane | ${e.name}`} />
                                        <p>
                                            {e.name}
                                        </p>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>
                <div className="top-Certificates">
                    <h2>Certificates</h2>
                    <div className="cards">
                        {
                            courses.map(e => {
                                return (
                                    <div className="card" key={e.name}>
                                        <img src={`https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/images/${e.name}-1.jpg`} width="300" alt={`Suyash Sonawane | ${e.name}`} />
                                        <p>
                                            {e.name}
                                        </p>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>



            </div>


        </motion.div>
    );
}

export default Certificates;
