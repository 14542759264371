import './Landing.css'
import eye from './sharingan.png'
import { Link, useHistory } from 'react-router-dom'
import * as Ai from 'react-icons/ai';

import { motion, startAnimation } from 'framer-motion'
import { Helmet } from 'react-helmet';
import { useState, useEffect, useRef } from 'react'

function Landing() {
  let [resume, setResume] = useState(null);
  let [limit, setLimit] = useState([1, 1, 1, 1, 1]);
  const face = "https://avatars.githubusercontent.com/u/35629339?v=4";
  const [pic, setPic] = useState(face)
  const player = useRef()
  let resume_link;
  fetch("https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/jsons/data.json")
    .then(e => {
      e.json().then(e => {
        resume_link = e;
        setResume(resume_link.resume)
      })
    })

  const clickHandler = () => {
    setLimit(state => state.slice(0, state.length - 1))
    if (limit.length == 1) {
      startAnimation()
    }
  }
  const startAnimation = () => {
    document.getElementById("mainApp").style.animation = "leave 1s ease 2s 3"
    document.getElementById("face-img").style.animation = "rotate 0.5s linear infinite"
    setPic(eye)
    player.current.play();
    player.current.volume = 1;
    document.documentElement.style.setProperty("--background", "#000");
    document.documentElement.style.setProperty("--text-main", "#b00");
    document.documentElement.style.setProperty("--text-secondary", "#bf0000");
    setTimeout(() => {
      document.getElementById("mainApp").style.animation = ''
      document.getElementById("face-img").style.animation = ''
      setPic(face)
      document.documentElement.style.setProperty("--background", "#fff");
      document.documentElement.style.setProperty("--text-main", "#5e5e5e");
      document.documentElement.style.setProperty("--text-secondary", "#6f6f70");

    }, 6000);
  }
  return (
    <>
      <Helmet>
        <title>Portfolio | Suyash Sonawane</title>
        <meta name="description" content="Hi I'm Suyash Sonawane 👋 A Software Developer and This is my web portfolio " />
        <meta property="og:title" content="Portfolio | Suyash Sonawane" />
        <meta property="og:description" content="Hi I'm Suyash Sonawane 👋 A Software Developer and This is my web portfolio" />
        <meta property="og:image" content="https://avatars0.githubusercontent.com/u/35629339?s=400&u=37fad97ae7ca47adc5405acdb1b67de97d6ff8bf&v=4" />
        <meta property="og:url" content={window.location.pathname + window.location.search} />
        <meta name="twitter:card" content="summa</meta>ry_large_image" />
      </Helmet>

      <motion.div className="view" exit={{ opacity: 0 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <audio src="/assets/AMATERASU.mp3" ref={player} hidden />
        <div className="indicator">
          {limit.length < 5 &&
            limit.map(e => (
              <div className="dot"></div>
            ))
          }
        </div>
        <div className="top">
          <div className="img-resume">
            <img src={pic} alt="Suyash Sonawane" className="face-img" id="face-img" onClick={clickHandler} />
            <br />
            {
              resume && <a href={resume} target="_blank" rel="noreferrer" className="anchors"><h2>View Resume</h2> </a>
            }
          </div>
          <div className="info">
            <p>
              <br />
              Hi <br />
              I’m <strong style={{ color: "var(--text-main)" }}>Suyash Sonawane</strong>
              <br />
              <br />
              Full Stack Software Developer <br /> AI & Deep Learning Enthusiast<br />

            </p>
          </div>
        </div>
        <div className="bottom">
          <Link to="/about" className="bottom-links">
            <Ai.AiOutlineArrowLeft />
            About
          </Link>
          <Link to="/projects" className="bottom-links">
            Projects
            <Ai.AiOutlineArrowRight />
          </Link>

        </div>
      </motion.div>
    </>
  );
}

export default Landing;
