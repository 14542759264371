import { Helmet } from "react-helmet";

function _404() {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column', color: 'var(--text-main)', transition: "1s ease", width: '80vw', margin: 'auto' }}>
            <Helmet>
                <title>404</title>
                <meta name="description" content="This page doesn't exist till now" />
                <meta property="og:title" content='404' />
                <meta property="og:description" content="This page doesn't exist till now" />
                <meta property="og:image" content='https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/404.jpg' />
                <meta property="og:url" content={window.location.pathname + window.location.search} />
                <meta name="twitter:card" content="summa</meta>ry_large_image" />
            </Helmet>
            <h1> 404 </h1> <br />
            <h2> Page not found </h2> <br />
            <p>You might have got here by mistake, no worries lets go</p> <br />
            <a href="/" className="anchors">Home</a>
        </div>
    );
}

export default _404;
