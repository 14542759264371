let top_projects = [
    {
        id: '0',
        title: "AI Dancer",
        short_desc: "A Long Short Term Memory Neural Network and Style GAN based Dancer that can generate new dance steps.",
        long_desc: ` <a href="https://devlibrary.withgoogle.com/products/ml/repos/SuyashSonawane-AI-Dancer"> <h4 align="">
<img src="https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/google-dev-logo.png" width="60"> <br />
Featured on Google Developer Library</h4> </a> <br>Imagine a dance video generated by an AI in which not only new dance steps are generated but the AI also creates it's own body to perform those steps.<br> Here I made a system which can accomplish this task to a smaller extent, I used 2 Neural Networks a <a target="_blank" class="anchors" href="https://en.wikipedia.org/wiki/Long_short-term_memory">LSTM</a> and a <a target="_blank" class="anchors" href="https://en.wikipedia.org/wiki/Generative_adversarial_network">GAN</a>. The LSTMs are used to predict the next step given previous 5 steps in the form of body points, the GAN then generates a frame of image from the points predicted by the LSTM that contains the human figure.<br> The architecture of the Neural Networks is provided in the Github repo and for the GAN I used a special GAN type known as <a target="_blank" class="anchors" href="https://arxiv.org/abs/1812.04948"> StyleGans </a>`,
        img: ["https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif", "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/2.gif"],
        techs: ['GANS', 'LSTM', 'TF', 'Showcased in Google'],
        icons: ['tf', 'py', 'web'],
        links: {
            github: "https://github.com/SuyashSonawane/AI-Dancer",
            youtube: 'https://www.youtube.com/watch?v=kDGQDVmToVI',

        },
        star: "Google",
        embeds: `<div class='embed-container'><iframe src='https://www.youtube.com/embed/kDGQDVmToVI' frameborder='0' allowfullscreen></iframe></div>`
    },
    {
        id: '10',
        title: "VsCode Datree",
        short_desc: "Simple VSCode Extension that allows you to run Datree tests on Kubernetes configurations.",
        long_desc: `<strong>Improper Kubernetes configurations can lead to ineffective and hard-to-maintain resource allocations, in worst cases it can break the whole CI/CD if not detected in earlier stages of deployment.</strong><br /> The VSCode Datree extension uses Datree under the hood to gather information about the YAML and Helm configurations and generate errors and suggestions that can be displayed right inside the code editor for the developer to act upon, as the Datree CLI currently doesn’t show the line number where the error is occurring this can help developers to find errors easily in large configurations. This open-source extension extends Datree’s functionality by allowing users to use custom policies made with Datree and use them directly through VSCode.
        The extension also simplifies it for the developer to correct the errors during the development phase rather than waiting for warnings later during the CI/CD pipelines.<br /><br/ >Read more about the project and my win here => <a href="https://medium.com/@suyashsonawane/vscode-datree-datrees-power-now-in-your-code-editor-747c9cfee610" target="_blank" class="anchors">VSCode Datree: Datree’s power now in your code editor 💪</a>`,
        url: "https://github.com/SuyashSonawane/vscode-datree/raw/main/media/head.gif",
        img: ['https://github.com/SuyashSonawane/vscode-datree/raw/main/media/head.gif'],
        techs: ['Node', 'Datree CLI', 'Hackathon Winner'],
        icons: ['node', 'javascript', 'web', 'react'],
        links: {
            youtube: 'https://www.youtube.com/watch?v=SjLCTpX0bxY',
        },
        star: "Winner",
        embeds: `
        <div class='embed-container'><iframe src='https://www.youtube.com/embed/SjLCTpX0bxY' frameborder='0' lowfullscreen></iframe></div>`,
        links: { web: "https://marketplace.visualstudio.com/items?itemName=suyashsonawane.vscode-datree", github: "https://github.com/SuyashSonawane/vscode-datree" },
    },
    {
        id: '01',
        title: "Driving AID",
        short_desc: "Realtime Collision Prediction and Prevention using Artificial Intelligence for Automobiles",
        long_desc: `Most of the road accidents occur due to lack of concetration of the driver on the surrounding vehicles. To contain this risk, I made a AI Driver Assistance system which alerts the driver for any sudden change in surrounding. <br> This alerts include 1. Closing of vehicles from front 2. Over-speeding by the driver 3. Sudden lane changes. Some collision prevention includes 1.Auto application of breaks and 2. Changing the course of the vehicle to prevent collision .<br> The vehicle detection is done using a Single Shot Detector <a target="_blank" class="anchors" href="https://towardsdatascience.com/understanding-ssd-multibox-real-time-object-detection-in-deep-learning-495ef744fab">MobileSSD</a> through TensorFlow. The system is simulated in GTA5 a PC game.`,
        url: "",
        img: ['https://camo.githubusercontent.com/0112044c294c24dd83407ff8ba9e143006e6ac28/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f31505f4759534654586e632f302e6a7067'],
        techs: ['MobileSSD', 'TF'],
        icons: ['tf', 'py'],
        links: {
            youtube: 'https://www.youtube.com/watch?v=1P_GYSFTXnc',
        },
        embeds: `
        <div class='embed-container'><iframe src='https://www.youtube.com/embed/1P_GYSFTXnc' frameborder='0' lowfullscreen></iframe></div>`
    },
    {
        id: '06',
        title: "Simple Controller",
        short_desc: "We introduce Simple Controller a secure, anonymous and free remote desktop controller, which allows you to connect to any machine of any platform through the browser without any hassle. ",
        long_desc: `Imagine your team is working on a service that is deployed on cloud, and some serious bug was just reported and the one guys who has the SSH key is working remotely, now rather than sending the ssh key through mail or any other communication medium, it is better to access the server through his machine, but how will you accomplish this task?`,
        url: "https://suyashsonawane.me/simple-controller/",
        img_url: "https://github.com/SuyashSonawane/simple-controller/raw/main/Simple%20Controller%20Documentation%20a68bcbf5cb60461b9dbefe5bb2c18880/Untitled.png",
        img: ['https://github.com/SuyashSonawane/simple-controller/raw/main/Simple%20Controller%20Documentation%20a68bcbf5cb60461b9dbefe5bb2c18880/Untitled.png'],
        techs: ['React.js', 'WebRTC', 'Sockets'],
        icons: ['python', 'react', 'web'],
        embeds: `
        <div class='embed-container'><iframe src='https://www.youtube.com/embed/NHJgaBzc0ig' frameborder='0' lowfullscreen></iframe></div>`,
        links: { web: "https://suyashsonawane.me/simple-controller/", github: "https://github.com/SuyashSonawane/simple-controller" },
    },
    {
        id: '02',
        title: "Maval GIS Attendance",
        short_desc: "Location based Attendance monitoring Android Application for Government Offices in Pune Maval Region ",
        long_desc: `Android Application that allows monitoring attendance of employee in Government Offices in Maval, Pune. The app allows to mark attendance depending on the current location of the user. <br> The manager is provided with a Dashboard that provides information of each employee through the desired time period. The information of each center and each employee can be exported to Excel sheet for a desired time period. OTP is used for security purposes <br> Built along with a colleague`,
        techs: ['Firebase', 'Android'],
        icons: ['android', 'firebase', 'angular', 'web'],
        img: ["https://play-lh.googleusercontent.com/sKv_gkkx8udONvaSio8OJa6qsdYsEz9NYSwTGHUNV2JeMJ6M5S6tdwZGSGeloO8bR6g=w720-h310-rw"],
        img_urls: ["https://play-lh.googleusercontent.com/sKv_gkkx8udONvaSio8OJa6qsdYsEz9NYSwTGHUNV2JeMJ6M5S6tdwZGSGeloO8bR6g=w720-h310-rw", "https://play-lh.googleusercontent.com/Fndqbm4otYYISwjmSk8F3Iz-3liyq_nwWSk6SAdydgRsVED5shCO1irp6WRaUKcdPA=w720-h310-rw", "https://play-lh.googleusercontent.com/LkN-vVht3ufwguapV0uudBcqbGE4KiT9KTLS__WRbfCOMrzHeSWXwd891rMDT8MCCQ=w720-h310-rw", "https://play-lh.googleusercontent.com/8vqelXsO3ncsnTug02biy0iBG6f1yXb8xiQHLNXz5ULgeRQ2X8irM3JShIax_A6Senk2=w720-h310-rw"],
        links: {
            playstore: 'https://play.google.com/store/apps/details?id=com.ganeshsuyash.mavalgisattendance',

        }
    },
    {
        id: '03',
        title: "Knowtify",
        short_desc: "Android App developed for Pimpri Chinchwad College of Engineering, Pune. This app provides an individual platform for the institute to share the official notices with its students.",
        long_desc: ` Notice Board App has been developed for the Department of Engineering, Pimpri Chinchwad College of Engineering, Pune. This app provides an individual platform for the institute to share the official notices with its students. <br> This app was developed under the First Year Department of Engineering, PCCOE and is gradually widening its scope for the entire departments. <br> Notices will be well classified into categories like Academic, Scholarship, Assignments and others. So, maintaining them and referring them in the future will become easier. The authorized faculty will be able to add new notices to the app with an Image, Title text and a short description about the image. The students will be notified on their smartphones instantly after it has been added by the faculty and they will be able to see the notices in the app. <br> Built along with a colleague`,
        techs: ['Firebase', 'Android'],
        icons: ['android', 'firebase', 'angular', 'ionic'],
        img: ["https://play-lh.googleusercontent.com/vN5x16o08DaYrqYekwEmseSo54Wda3kG6NXT8DlAaOJe9ndhN1PjC5v5OVCgFWbiNG19=w1920-h969-rw"],
        img_urls: ["https://play-lh.googleusercontent.com/vN5x16o08DaYrqYekwEmseSo54Wda3kG6NXT8DlAaOJe9ndhN1PjC5v5OVCgFWbiNG19=w1920-h969-rw", "https://play-lh.googleusercontent.com/r-LIv8AcnsMgDmtNS-zUvHn9HYCBubaeJDNSFyUIOmmYcegwShEQ0wjo-YymkyqII6Tg=w1920-h969-rw", "https://play-lh.googleusercontent.com/WiNQGI5dy-iAswt45uIbl4RCtmKMa1CdIAkkbRh1an-_LKtmvOujgnLSZElXL8enCA=w1920-h969-rw", "https://play-lh.googleusercontent.com/ysJop8J-dqZfo8g8SZli0azwlDzoZU8Wo6LY1yqJ1E6DM7qJ83iunwEHUaTmjfdZIw=w1920-h969-rw"],
        links: {
            playstore: 'https://play.google.com/store/apps/details?id=com.devscollab.knowtify',

        }
    },
    {
        id: '04',
        title: "Mask Detector",
        short_desc: "Realtime Camera enabled Web Application for detecting whether the subject is wearing mask in public areas amid of Covid-19  ",
        long_desc: `Covid-19 hit the world and wearing masks became the necessity, still in small places and on shops some of us forgot social distancing and masks due to lack of amenities which could lead to spreading of the virus. <br> To contain this condition I made a simple and easy to setup web application can that be used on shops to detect people not wearing masks and alert the shop owners to take cautions. It is as simple as opening a website and placing the mobile on the desk. <br> I used Convolutional Neural Network to train a classifier that classifies the person face as wearing the mask or not.`,
        techs: ['TF', 'TF.js'],
        icons: ['tf', 'web'],
        img: ['https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/mask.jpg'],
        links: { web: "https://mask-detector.surge.sh/", github: "https://github.com/SuyashSonawane/Mask-Detector" },
        embeds: `
        <div class='embed-container'><iframe src='https://www.youtube.com/embed/uECdm-_oA1I' frameborder='0' lowfullscreen></iframe></div>`
    },
    {
        id: '05',
        title: "PCET Covid Task Force",
        short_desc: "CRM inspired system to help more than 1000 people get information about critical facilities during pandemic.",
        long_desc: `We try to provide help in areas like Lab Testing, Hospital admission, Oxygen bed, Ventilator, Plasma, Medicines, Moral Support, Arranging Isolation Beds, Medical suggestions from Doctors, etc. We are a committee of students trying to help society through this pandemic situation because we believe 'Helping one person might not change the world, but it could change the world for one person!'`,
        techs: ['React.js', 'Express.js', 'MongoDB'],
        icons: ['react', 'mongo', 'node', 'azure', 'web'],
        img: ['https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/pctf-home.jpg'],
        img_urls: [
            'https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/pctf-home.jpg',
            'https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/pctf-helpdesk.jpg',
            'https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/pctf-volunteer.jpg',
        ],
        links: { web: "https://pctfhelp.me/", github: "https://github.com/devscollab/pcet-covid-task-force" },
    },

]

export default top_projects