let accomplishments = [
    {
        title: "Published paper in SCOPUS Indexed Computational Intelligence Conference ",
        desc: "Our paper on <b>Autonomous Vehicles: A survey on Sensor Fusion, Lane Detection & Drivable Area Segmentation</b> was published in International Conference on Computational Intelligence in Data Mining (ICCIDM-2021). We  present a detailed survey of existing and state-of-the-art approaches for these modules.Understanding these techniques and how they work can lay a proper foundation for the planning and acting phase of autonomous vehicle systems.",
        img: 'https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/images/Proposed%20Block%20Diagram.png',
        date: "20/8/2021",
        outlink: "https://drive.google.com/file/d/1szUsrdAzkWEZjCSjTq47wh3OskpUugkB/view?usp=sharing",
        outInfo: "Read Paper"
    },
    {
        title: "Project Showcased on Google Developer Library",
        desc: "A Long Short Term Memory Neural Network and Style GAN based Dancer that can generate new dance steps. A short summary of the project: Neural Network 🧠 sees 👀 dancing videos 💃, tries to learn and create its own dance steps 🕺",
        img: 'https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif',
        date: "10/8/2021",
        outlink: "https://devlibrary.withgoogle.com/products/ml/repos/SuyashSonawane-AI-Dancer",
        outInfo: "Check it out"
    },
    {
        title: "Selected as Microsoft Student Learn Ambassador 2020",
        desc: "The Microsoft Learn Student Ambassadors is a program to sponsor students majoring in disciplines related to technology. The MSP program enhances students' employability by offering training in skills not usually taught in academia, including knowledge of Microsoft technologies.",
        embed: `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/D9QcOFfIks8" frameborder="0" allowfullscreen></iframe>`,
        date: "12/7/2021",
        outInfo: "Submission Video"
    },

    {
        title: "Speaker at Pie & AI: Pune - Building your own DL Library in JavaScript",
        desc: "Pie & AI is a series of DeepLearning.AI meetups independently hosted by community groups. Join us for the event to get deep into Deep Learning.Here, in this session, you will go from learning basic concepts of deeplearing to building your very own deeplearning library with basic functionalities.It will help the attendees gain a better grasp of the important concepts in DL.",
        embed: `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/P-gFADnCqRk" frameborder="0" allowfullscreen></iframe>`,
        date: "30/1/2021",
        outlink: "https://www.youtube.com/channel/UCkkPWGhKp4_wr1g10afipOQ",
        outInfo: "Visit DevsCollab"
    },
    {
        title: "Selected as Google Developer Student Clubs PCCoE Lead 2020",
        desc: "DSC, Developer Student Clubs is a initiative by Google Developers to help student developers grow their knowledge in a peer-to-peer learning environment and build solutions for local businesses and their community. I was selected from my college PCCoE to be the Lead through a series of interviews by Google Devs. There are total 278 DSCs in India",
        embed: `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/kfDKxzQ_fRQ" frameborder="0" allowfullscreen></iframe>`,
        date: "28/8/2020",
        outlink: "https://dsc.community.dev/pimpri-chinchwad-college-of-engineering/",
        outInfo: "Visit DSCPCCoE"
    },
    {
        title: "Started Internship at CDAC Mumbai as an AI research intern",
        desc: "We as a team, work on Fake Video Detection in social media space. Fake Videos are generated by changing the faces in the original video to some other faces. DeepFakes have created havoc in privacy of people in online space.",
        img: "https://wp.technologyreview.com/wp-content/uploads/2019/09/deepfakecoffee_2-10.jpg?w=3000?crop=0px,0px,3200px,1800px&w=3000px",
        date: "27/8/2020",
        outlink: "https://www.cdac.in/",
        outInfo: "Visit CDAC"
    },
    {
        title: "Selected as Web Master for ACM Student Chapter",
        desc: "I was selected as Webmaster for my college ACM Student Chapter CESA. We at CESA conduct various activities for students, technical and non technical to improve their abilities as a technocrats. I as a webmaster hold responsibility of the website and technical events along with the registration processes",
        img: "http://pccoe.acm.org/images/banner2.jpg",
        date: "22/8/2020",
        outlink: "http://pccoe.acm.org/",
        outInfo: "Visit PCCoE ACM"
    },
    {
        title: "Created Attendance Management system for Government Agency",
        desc: "Myself along with my colleague Ganesh Shinde we developed a system to help Government Offices in Maval region of Pune in monitoring attendance of the employees based on their location at the given time.",
        img: "https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/banner.png",
        date: "30/7/2020",
        inlink: "projects/02"
    },
    {
        title: "Participated in Google Ad Grants Challenge",
        desc: `Couple of months before me and our team enrolled in Google's Online Marketing 💸. We were connected with a NGO Prajna Foundation 👧. Learnt various online marketing features.`,
        img: "https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/images/Google%20OMC.jpg",
        date: "02/8/2020 ",
        outlink: "https://www.google.co.in/grants/",
        outInfo: "Google OMC"
    },
    {
        title: "Completed Web Developer Internship at LoGeek Solutions",
        desc: `Working on real life projects and clients helps you to develop your skills and understanding of the respective developing framework your using 🚀.I got this opportunity in the form of Internship from LoGeek Solutions and Ketan Desale Sir to work on Web and Content Development for client Kajal Maheshwari regarding Pune Business School website and social handles.I thank all of them for mentoring me to find a optimal path towards being a successful Developer🙌.`,
        img: "https://www.logeeksolutions.in/assets/images/logo.png",
        date: "17/2/2020 - 30/10/2020 ",
        outlink: "https://www.logeeksolutions.in/",
        outInfo: "Visit LoGeek"
    },
    {
        title: "Published Article in Towards DataScience",
        desc: `My article on Understanding and Implementing Neural Networks in Java from Scratch 💻, got published in Towards DataScience Medium Publication, a reputed DataScience publication. The article focus on understanding the complex concepts of Neural Networks through JAVA `,
        img: "https://miro.medium.com/max/560/1*uSKKL19eFimHkj1fuO6MSQ.png",
        date: "21/6/2020",
        outlink: "https://towardsdatascience.com/understanding-and-implementing-neural-networks-in-java-from-scratch-61421bb6352c",
        outInfo: "Read Article"
    },
    {
        title: "2nd Runner Up in SIH Internal Hackathon ",
        desc: `Our team DevsCollab secured 2nd Runner Up position at PCCoE PUNE organized Smart Internal Hackathon for Smart India Hackathon selection. under the guidance of Prof. Ketan Desale,
        Our project was based on office space surveillance for industries  `,
        img: "https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/images/SIH.jpg",
        date: "20/1/2020",
        outlink: "https://www.sih.gov.in/",
        outInfo: "About SiH"
    },
    {
        title: "Published Article in CoinMonks",
        desc: `My first Medium Post Creating Your First DAPP:Beginner's Guide on creating a Decentralized Application with Web3.js and Truffle Framework was published by Coinmonks a Non-profit Crypto Education Publication on Medium. The article focus on making a simple DAPP deployment on the local network, also creation of a front-end which will interact with our smart contract on the BlockChain. `,
        img: "https://miro.medium.com/max/1100/0*fyJ0lGc1PYEcQDnH",
        date: "21/6/2020",
        outlink: "https://medium.com/coinmonks/creating-your-first-dapp-beginners-guide-a320d0e4897d",
        outInfo: "Read Article"
    },
]

export default accomplishments;