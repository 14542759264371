import './App.css';
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import * as Gi from 'react-icons/gi';
import { AnimatePresence } from 'framer-motion'
import About from './Components/About/About'
import { Route, Switch, NavLink, useHistory } from 'react-router-dom'
import Landing from './Components/Landing/Landing';
import Projects from './Components/Projects/Projects'
import Certificates from './Components/Certificates/Certificates'
import Accomplishments from './Components/Accomplishments/Accomplishments'
import SocialBanner from './Components/SocialBanner/SocialBanner';
import Detailed from './Components/Detailed/Detailed';
import Dark from './Components/Dark';
import _404 from './Components/404';
import SwitchApp from './Components/Switch/Switch';
import ReactGA from 'react-ga';
import { useEffect, useState } from 'react';
import Egg from './Components/Egg';
ReactGA.initialize('G-X87CR1GZHX');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  const history = useHistory()
  const [egg, setEgg] = useState(false)

  history.listen(({ pathname }) => {
    pathname = pathname.split('/')[1]
    if (pathname === 'egg')
      setEgg(true)
  })
  return (
    !egg ?
      <div id="mainApp">
        <SwitchApp />
        <SocialBanner />
        <div className="App">
          <nav>
            <NavLink to="/" exact className="nav-item">
              <Ai.AiFillHome size={24} className="icons" /> <br />
              <label>Home </label>
            </NavLink>
            <NavLink to="/about" exact className="nav-item">
              <Fa.FaUserCircle size={24} className="icons" /> <br />
              <label>About Me </label>
            </NavLink>
            <NavLink to="/projects" className="nav-item">
              <Ai.AiFillProject size={24} className="icons" /> <br />
              <label>Projects </label>
            </NavLink>
            <NavLink className="nav-item" exact to="/certificates">
              <Fa.FaCertificate size={24} className="icons" /> <br />
              <label>Certificates </label>
            </NavLink>
            <NavLink to="/accomplishments" className="nav-item">
              <Gi.GiAchievement size={24} className="icons" /> <br />
              <label>Accomplishments </label>
            </NavLink>
          </nav>
          <div className="content">
            <AnimatePresence exitBeforeEnter>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route path="/about" component={About} />
                <Route path="/projects" exact component={Projects} />
                <Route path="/certificates" component={Certificates} />
                <Route path="/accomplishments" component={Accomplishments} />
                <Route path="/projects/:id" exact component={Detailed} />
                <Route path="/dark" exact component={Dark} />
                <Route component={_404} />
              </Switch>
            </AnimatePresence>
          </div>
        </div>
      </div>
      : <Egg />
  );
}

export default App;
