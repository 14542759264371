import { motion } from 'framer-motion'
import './Detailed.css'
import top_projects from '../../Data/top-projects.js'
// import projects from '../../Data/projects.js'
import * as Si from "react-icons/si";
import { FiMousePointer } from "react-icons/fi"
import { Helmet } from 'react-helmet';

function Detailed({ match }) {
  let id = match.params.id
  let project = top_projects.filter(e => {
    return e.id === id
  })[0]
  // console.log(project)
  if (project)
    return (
      <motion.div className="DetailedView" exit={{ opacity: 0 }} initial={{ x: "3em" }} animate={{ x: 0 }}>
        <Helmet>
          <title>Suyash Sonawane's {project.title}</title>
          <meta name="description" content={project.short_desc} />
          <meta property="og:title" content={`Suyash Sonawane's ${project.title}`} />
          <meta property="og:description" content={project.short_desc} />
          <meta property="og:image" content={project.img[0]} />
          <meta property="og:url" content={window.location.pathname + window.location.search} />
          <meta name="twitter:card" content="summa</meta>ry_large_image" />
        </Helmet>
        <h1>{project.title}</h1>
        <div className="DetailedContent">

          <div className="tech-stack">

            {
              project.icons.map(icon => {
                switch (icon) {
                  case 'tf':
                    return <Si.SiTensorflow className="ts-icons" title="Tensorflow" key={Math.random()} />
                  case 'py':
                    return <Si.SiPython className="ts-icons" title="Python" key={Math.random()} />
                  case 'web':
                    return <>
                      <Si.SiHtml5 className="ts-icons" key={Math.random()} />
                      <Si.SiJavascript className="ts-icons" key={Math.random()} />
                      <Si.SiCss3 className="ts-icons" key={Math.random()} />
                    </>
                  case 'android':
                    return <Si.SiAndroid className="ts-icons" key={Math.random()} />

                  case 'firebase':
                    return <Si.SiFirebase className="ts-icons" key={Math.random()} />
                  case 'angular':
                    return <Si.SiAngular className="ts-icons" key={Math.random()} />
                  case 'react':
                    return <Si.SiReact className="ts-icons" key={Math.random()} />
                  case 'ionic':
                    return <Si.SiIonic className="ts-icons" key={Math.random()} />
                  case 'mongo':
                    return <Si.SiMongodb className="ts-icons" key={Math.random()} />
                  case 'node':
                    return <Si.SiNodeDotJs className="ts-icons" key={Math.random()} />
                  case 'azure':
                    return <Si.SiMicrosoftazure className="ts-icons" key={Math.random()} />
                  case 'python':
                    return <Si.SiPython className="ts-icons" key={Math.random()} />

                  default:
                    return ''

                }
              })
            }

          </div>
          <p>
            {project.short_desc}
          </p>
          <div className="embeds">
            {
              project.embeds && <td dangerouslySetInnerHTML={{ __html: project.embeds }} />
            }
          </div>
          <div className="images">
            {
              project.img_urls &&
              project.img_urls.map(imgs => {
                return <img src={imgs} alt={project.title} key={imgs} />
              })
            }
          </div>
          <p>
            <strong>Detailed Info:</strong>
          </p>
          <p>
            <td dangerouslySetInnerHTML={{ __html: project.long_desc }} />
          </p>
          <p>
            <strong>More Links:</strong>
          </p>
          <div className="more-links">
            <ul>
              {project.links &&
                Object.entries(project.links).map(el => {

                  switch (el[0]) {
                    case "github":
                      return <li key={el[1]}><a href={el[1]} target="_blank" rel="noreferrer" ><Si.SiGithub /> Source Code</a></li>

                    case "youtube":
                      return <li key={el[1]}><a href={el[1]} target="_blank" rel="noreferrer" ><Si.SiYoutube /> Explanation</a></li>

                    case "playstore":
                      return <li key={el[1]}><a href={el[1]} target="_blank" rel="noreferrer" ><Si.SiAndroid /> PlayStore</a></li>

                    case "web":
                      return <li key={el[1]}><a href={el[1]} target="_blank" rel="noreferrer" ><FiMousePointer /> Try out</a></li>



                    default:
                      return ""
                  }

                })
              }
            </ul>
          </div>

        </div>



      </motion.div>
    );
  else
    return (
      <motion.div className="DetailedView" exit={{ opacity: 0 }} initial={{ x: "3em" }} animate={{ x: 0 }}>
        <Helmet>
          <title>Unknown Project</title>
          <meta name="description" content="I haven't made this project yet, may be you got the url wrong" />
          <meta property="og:title" content="Unknown Project" />
          <meta property="og:description" content="I haven't made this project yet, may be you got the url wrong" />
          <meta property="og:image" content="https://raw.githubusercontent.com/SuyashSonawane/reactserver/master/404.jpg" />
          <meta property="og:url" content={window.location.pathname + window.location.search} />
          <meta name="twitter:card" content="summa</meta>ry_large_image" />
        </Helmet>
        <h1> Unknown Project :( </h1> <br />
        <h2> Project not found </h2> <br />
        <p>You might have got here by mistake, no worries lets go</p> <br />
        <a href="/" className="anchors">Home</a> or
        <a href="/projects" className="anchors">Projects</a>
      </motion.div>
    )
}

export default Detailed;
