import { Redirect } from "react-router-dom";
import {useState} from 'react'
function Dark() {
    let [redirect, setRedirect] = useState(false)    
    document.title = "Dark | Suyash Sonawane"

    setTimeout(() => {
        document.documentElement.style.setProperty("--text-main", "#ffff");
        document.documentElement.style.setProperty("--text-secondary", "#d1d1d1");
        document.documentElement.style.setProperty("--background", "#0f0f0f");
        setTimeout(() => {
            setRedirect(true)
        }, 800);
    }, 800);

    if (redirect)
        return <Redirect to="/" />
    else
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center',height:'100%',flexDirection:'column', color:'var(--text-main)',transition:"1s ease"}}>
                <h2> Turning into dark mode </h2> <br/>               
            </div>
        );
}

export default Dark;
