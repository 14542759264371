import './SocialBanner.css'
import * as Ai from "react-icons/ai";
import * as Fa from "react-icons/fa";
import {useState} from 'react'

function SocialBanner() {
    let [active, setActive] = useState(false)
   
    return (
        <>
        <div className="banner-big">
            <a className="icons" id="github" href="https://github.com/SuyashSonawane" target="_blank" rel="noreferrer">
                <Ai.AiFillGithub size={40}/>
            </a>
            <a className="icons" id="linkedin" href="https://www.linkedin.com/in/suyash-sonawane-44661417b/" target="_blank" rel="noreferrer">
                <Ai.AiFillLinkedin size={40}/>
            </a>
            <a className="icons" id="mail" href="mailto:suyashsonawane007@gmail.com" target="_blank" rel="noreferrer">
                <Ai.AiFillMail size={40}/>
            </a>
            <a className="icons" id="twitter" href="https://twitter.com/SuyashYSonawane" target="_blank" rel="noreferrer">
                <Ai.AiFillTwitterSquare size={40}/>
            </a>
            <a className="icons" id="kaggel" href="https://www.kaggle.com/suyashsonawane" target="_blank" rel="noreferrer">
                <Fa.FaKaggle size={40} />
            </a>
        </div>
            <div className={active ? "banner-small banner-active" : "banner-small"}>
                {!active && <Ai.AiOutlineSwapLeft size={25} onClick={()=>{setActive(!active)}}/>}
                {active && 
                    <>
                    <Ai.AiOutlineSwapRight size={25} onClick={() => { setActive(!active) }} />
                    <a className="icons" id="github" href="https://github.com/SuyashSonawane" target="_blank" rel="noreferrer">
                        <Ai.AiFillGithub size={25}/>
                    </a>
                    <a className="icons" id="linkedin" href="https://www.linkedin.com/in/suyash-sonawane-44661417b/" target="_blank" rel="noreferrer">
                        <Ai.AiFillLinkedin size={25}/>
                    </a>
                    <a className="icons" id="mail" href="mailto:suyashsonawane007@gmail.com" target="_blank" rel="noreferrer">
                        <Ai.AiFillMail size={25}/>
                    </a>
                    <a className="icons" id="twitter" href="https://twitter.com/SuyashYSonawane" target="_blank" rel="noreferrer">
                        <Ai.AiFillTwitterSquare size={25}/>
                    </a>
                    <a className="icons" id="kaggel" href="https://www.kaggle.com/suyashsonawane" target="_blank" rel="noreferrer">
                        <Fa.FaKaggle size={25} />
                    </a>

 </>}
            
        </div>
        </>
  )
}

export default SocialBanner;
