let projects = [
    {
        id: '10',
        title: "Anime Generator",
        short_desc: "Generative Adversarial Networks based Anime face generator with the ability to control top features learned by the neural network ",
        long_desc: "",
        url: "https://share.streamlit.io/suyashsonawane/anime-gan/main/app.py",
        img_url: "https://camo.githubusercontent.com/0112044c294c24dd83407ff8ba9e143006e6ac28/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f31505f4759534654586e632f302e6a7067"
    },
    {
        id: '11',
        title: "Lite Neural Networks",
        short_desc: "Javascript Library for faster Neural Network Implementation on low-end devices",
        long_desc: "",
        url: "https://suyashsonawane.github.io/Lite-Neural-Networks/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },

    {
        id: '12',
        title: "Website for Pune Business School ",
        short_desc: "Website developed for PCET's Pune Business School as a part of Paid Internship at LoGeek Solutions  ",
        long_desc: "",
        url: "https://punebusinessschool.com/",
        img_url: "https://play-lh.googleusercontent.com/sKv_gkkx8udONvaSio8OJa6qsdYsEz9NYSwTGHUNV2JeMJ6M5S6tdwZGSGeloO8bR6g=w720-h310-rw"
    },
    {
        id: '13',
        title: "DSC WoW Badge Maker",
        short_desc: "Computer Vision based badge maker for creating Badges for Developer Student Clubs WOW event.",
        long_desc: "",
        url: "https://badge-maker.surge.sh/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '13',
        title: "3D Interior Designer",
        short_desc: "A webapp developed using Three.js for clients under the internship at LoGeek for showcasing various textures on 3D models of rooms",
        long_desc: "",
        url: "https://suyashsonawane.github.io/3D/living-room/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Fashion Classifier",
        short_desc: "A Simple Fashion Classifier Made in TensorFlow.js and Trained on MNIST Dataset",
        long_desc: "",
        url: "https://mnist-fashion.surge.sh/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Genetic Flappy Bird",
        short_desc: "A Genetically Flappy Bird that learns over time how to play the game using Genetic Algorithm and eventually becomes the best player.",
        long_desc: "",
        url: "",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Neural Net Playground",
        short_desc: "A webapp developed using TensorFlow.js that allows user to select different optimizers, loss function, hyper-parameters for the toy Neural Network",
        long_desc: "",
        url: "https://suyashsonawane.github.io/myneuralnet/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "MOC DAPP",
        short_desc: "Mousse on Chain is a Decentralized Application built on Ethereum Network",
        long_desc: "",
        url: "https://moc-dapp.surge.sh/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "BlockChain Simulator",
        short_desc: "Webapp build in Angular, simulates the process happening on real blockchain to gain insights and know more about the concepts. ",
        long_desc: "",
        url: "https://angular-blockchain.surge.sh/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Sentimental Analysis",
        short_desc: "A webapp that can be also be used as an rest api for sentimental analysis of sentences. It can detect the nature of sentence",
        long_desc: "",
        url: "https://sentimentalapi.herokuapp.com/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Linear Regression Visualizer ",
        short_desc: "A webapp built with TensorFlow.js that shows the Linear Regression Algorithm in action",
        long_desc: "",
        url: "https://suyashsonawane.me/LR_in_TF.js/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "RTC video",
        short_desc: "A WebRTC based video calling webapp, allows you to connect with your friend allowing  private channel with minimal quality loss.Connect with QR code or share the link.",
        long_desc: "",
        url: "https://rtc-video.surge.sh/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Coding Hub",
        short_desc: "A online platform where developers can meet with other developers in their vicinity.Using Google Map API get the location of our fellow developers. Location updated in realtime.",
        long_desc: "",
        url: "https://codinghub-92abe.web.app/login",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Ninja Smoothies",
        short_desc: "A CURD smoothie recipe webapp where any-one can add their smoothie recipe for the community",
        long_desc: "",
        url: "https://suyashsonawane.me/Ninja-Smoothies/#/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Geo-Dev",
        short_desc: "Global Chatting webapp for the community",
        long_desc: "",
        url: "https://geo-devs-b3076.firebaseapp.com/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Commits Tracker",
        short_desc: "Website for tracking commits in the repo with the help of Github API, originally made for my own use.",
        long_desc: "",
        url: "http://suyashsonawane.me/commits-tracker",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "ADAM",
        short_desc: "A simple voice recognition software built with .Net framework It can perform various tasks such as text-reader, music player , browse web , HTML scraping ,telling jokes , adding commands to database ",
        long_desc: "",
        url: "",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Sorter",
        short_desc: "A sorting algorithms visualizer made as an assignment.",
        long_desc: "",
        url: "https://sorter.surge.sh",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Rangen",
        short_desc: "A random number generator with Python Web development framework Flask. It can also server as an API giving back Json response.",
        long_desc: "",
        url: "https://rangen.herokuapp.com/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "SEB-Server",
        short_desc: "A web server for allowing students to download assignments and lecturer's ppt directly from the server. Using github api for file serving purpose.",
        long_desc: "",
        url: "https://seb-server.surge.sh/",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "DDA Visualizer",
        short_desc: "A webapp for visualizing the working of DDA line drawing algorithm, where user can try various conditions and see the results instantly.",
        long_desc: "",
        url: "https://suyashsonawane.github.io/DDA-Visualizer/index.html",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },
    {
        id: '14',
        title: "Eigen Values",
        short_desc: "A webapp for cross checking the solutions for eigen values and eigen vectors problems. ",
        long_desc: "",
        url: "https://eigenvalues.surge.sh",
        img_url: "https://github.com/SuyashSonawane/AI-Dancer/raw/master/assets/1.gif"
    },

]

export default projects 